import $ from 'jquery';
export default class Home {
  constructor() {
    this.morePostLoad()
  }
  morePostLoad(){
    let now_post_num = 15// 現在表示されている件数
    let get_post_num = 15// もっと読み込むボタンで取得する件数

    //archive側で設定したdata属性の値を取得
    const load = $(".load")
    const post_type = load.data("post-type")
    const all_count = load.data("count") //カスタム投稿の全件数

    //admin_ajaxにadmin-ajax.phpの絶対パス指定（相対パスは失敗する）
    const host_url = location.protocol + "//" + location.host
    const admin_ajax = host_url + '/wp-admin/admin-ajax.php'

    $(document).on("click", ".jsc-more-btn", ()=> {
      //読み込み中はボタン非表示
      $('.jsc-more-btn').remove()

      //ajax処理。data{}のactionに指定した関数を実行、完了後はdoneに入る
      $.ajax({
          type: 'POST',
          url: admin_ajax,
          data: {
              'action' : 'my_ajax_action', //functions.phpで設定する関数名
              'now_post_num': now_post_num,
              'get_post_num': get_post_num,
              'post_type': post_type,
              'post_taxonomy': '',
              'post_term':''
          },
      })
      .done((data)=>{ //my_ajax_action関数で取得したデータがdataに入る
          //.loadにデータを追加
          load.append(data); 
          //表示件数を増やす
          now_post_num = now_post_num + get_post_num; 
          //まだ全件表示されていない場合、ボタンを再度表示
          if(all_count > now_post_num) { 
              load.after('<button class="p-blog__more__btn jsc-more-btn">もっと読み込む</button>')
          }
      })
      .fail(()=>{
          alert('エラーが発生しました')
      })
    })
  }
}