//コンタクトフォーム関連
import $ from "jquery";

export default class Contact {
  constructor() {
    this.errorScroll();
  }
  errorScroll() {
    document.addEventListener(
      "wpcf7invalid",
      function (event) {
        setTimeout(function () {
          const position =
            $("span.wpcf7-not-valid-tip:first").get(0).getBoundingClientRect()
              .top + window.pageYOffset;
          const headerHeight = $("#jsi-header").innerHeight() + 100;
          const scrollAmount = position - headerHeight;
          $("html,body").animate(
            {
              scrollTop: scrollAmount,
            },
            500
          );
        }, 100);
      },
      false
    );
  }
}
