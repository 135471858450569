import $ from 'jquery';
export default class Menu {
  constructor() {
    this.inflowScroll()
    this.smoothScroll()
  }
  
  inflowScroll(){
    const url = $(location).attr('href'),
    headerHeight = $('header').outerHeight() + 30;

    // urlに「#」が含まれていれば
    if(url.indexOf("#") != -1){
      // urlを#で分割して配列に格納
      const anchor = url.split("#"),
      // 分割した最後の文字列（#◯◯の部分）をtargetに代入
      target = $('#' + anchor[anchor.length - 1]),
      // リンク先の位置からheaderHeightの高さを引いた値をpositionに代入
      position = Math.floor(target.offset().top) - headerHeight
      // positionの位置に移動
      $("html, body").animate({scrollTop:position}, 400)
    }
  }
  
  smoothScroll(){
    const $inPageLink = $('a[href^="#"]')
    console.log('menu')
    $inPageLink.on('click',(e)=>{
      console.log('menu')
      $('.p-controller__btn').removeClass('is-active')
      $(e.currentTarget).addClass('is-active')
      const targetId = $(e.currentTarget).attr('href')
      const adjustHeaderHeight = $('#jsi-header').innerHeight()
      const targetPosition = $(targetId).offset().top - adjustHeaderHeight - 22
      const scrollSpeed = 400
      $('body,html').animate({scrollTop:targetPosition}, scrollSpeed, 'swing')
    })
  }
}