import $ from "jquery";

export default class Footer {
  constructor() {
    this.toggleBanner();
  }
  toggleBanner() {
    const $banner = $(".jsc-banner");
    const targetSectionTop = $(".jsc-inquiry-section").offset().top;
    $(window).on("scroll", () => {
      if ($(window).scrollTop() + $(window).outerHeight() > targetSectionTop) {
        $banner.addClass("is-fade");
      } else {
        $banner.removeClass("is-fade");
      }
    });
  }
}
