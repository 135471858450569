import Slider from "./Slider";
import Home from "../projects/Home";
import Menu from "../projects/Menu";
import Load from "./Load";
import Header from "./Header";
import Footer from "./Footer";
import Contact from "./Contact";
export default class Router {
  constructor() {
    this.model = {
      pathName: location.pathname,
    };

    this.initRouting();
  }

  initRouting() {
    const pathName = this.model.pathName;
    switch (pathName) {
      case "/":
        new Slider();
        new Home();
        new Header();
        new Footer();
        break;
      case "/menu/":
        new Menu();
        new Header();
        new Footer();
        break;
      case "/contact/":
        new Header();
        new Contact();
        break;
      default:
        new Slider();
        new Load();
        new Menu();
        new Header();
        new Footer();
        break;
    }
  }
}
