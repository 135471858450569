
import $ from 'jquery';

export default class Header {
  constructor() {
    this.toggleHamburger()
  }
  toggleHamburger(){
    const $hamburgerBtn = $('.jsc-hamburger')
    $hamburgerBtn.on('click',(e)=>{
      $(e.currentTarget).toggleClass('is-open')
      $('.jsc-sp-modal').toggleClass('is-open')
    })
  }
  
}