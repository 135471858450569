import $ from 'jquery';
import Swiper from 'swiper/bundle';
import 'swiper/css'

export default class Slider {
  constructor() {
    this.fvSlider()
  }

  fvSlider(){
    const swiper = new Swiper('.jsc-fv-slider',{
      loop: true,
      centeredSlides: true,
      pagination: {
        el: '.jsc-fv-slider-dots',
        type: 'bullets',
        clickable: 'falese' ,
      },
      navigation: {
        nextEl: '.jsc-fv-slider-next',
        prevEl: '.jsc-fv-slider-prev',
        hideOnClick: true,
      },
      breakpoints: {
        // Swiper のコンテナ幅が 420px 以上の場合
        320: {
          slidesPerView: 1.2,
          spaceBetween: 12
        },
        // Swiper のコンテナ幅が 560px 以上の場合
        995: {
          slidesPerView: 'auto',
          spaceBetween:40,
        }
      },
    })

    const slideLength = swiper.$el
      
    for(let i = 0;i <slideLength;i++){
      $(".jsc-fv-slider-dots").append('<i class="jsc-fv-slider-dot"></i>')
    }
    addCurrentDot()
    
    function addCurrentDot(){
      console.log(swiper.activeIndex)
      const slideCurrent = swiper.activeIndex
      // console.log(slideCurrent)
      $(".jsc-fv-slider-dot").removeClass('is-active')
      $(".jsc-fv-slider-dot").eq(slideCurrent).addClass('is-active')
    }
  }
}